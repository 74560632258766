<template>
    <header>
        <div class="top-bar">
            <div class="top-bar-infos">Pour toutes informations : <a href="tel:0486315384">+33 (0)4 86 31 53 84</a></div>
        </div>
        <div class="nav-bar">
            <router-link :to="{ name : 'Home' }" class="logo-container">
                <img src="./../assets/logo-mvc.png" alt="logo MVC" class="logo" />
            </router-link>
            <div class="menu-container">
                <nav class="menu">
                    <router-link :to="{ name : 'Home' }">Accueil</router-link>
                    <router-link :to="{ name : 'Solutions'}">Solution & Fonctionnalités</router-link>
                    <router-link :to="{ name : 'Avantages'}">Avantages</router-link>
                    <router-link :to="{ name : 'References'}">Références Clients</router-link>
                    <router-link :to="{ name : 'About'}">A propos de nous</router-link>
                    <router-link :to="{ name : 'Contact'}">Contact</router-link>
                </nav>
                <div class="menu-sep"></div>
                <router-link :to="{ name : 'Demo'}" class="menu-demo">demander une démo</router-link>
            </div>
            <!-- MENU RESP -->
            <div class="menu-resp">
                <div class="burger-nav" @click="open = !open " :class="open ? 'open' : ''">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <nav class="menu-header-resp-content" v-if="open">
                    <router-link :to="{ name : 'Home' }">Accueil</router-link>
                    <router-link :to="{ name : 'Solutions'}">Solution & Fonctionnalités</router-link>
                    <router-link :to="{ name : 'Avantages'}">Avantages</router-link>
                    <router-link :to="{ name : 'References'}">Références Clients</router-link>
                    <router-link :to="{ name : 'About'}">A propos de nous</router-link>
                    <router-link :to="{ name : 'Contact'}">Contact</router-link>
                    <router-link :to="{ name : 'Demo'}" class="menu-demo-resp">Demander une démo</router-link>
                </nav>
            </div>
        </div>
    </header>
</template>


<script>
export default {
    name : 'HeaderApp',
    data(){
        return {
            open : false
        }
    },
    watch : {
         '$route.name' : function() {
             this.open = false
         }
    }
}
</script>

<style scoped lang="scss">
    header {
        display:flex;
        flex-direction: column;
        align-items: center;
        position:fixed;
        top:0;
        left:0;
        right:0;
        background: #fff;
        z-index:999;
    }

    .top-bar {
        width:100%;
        height:30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $mainGradiant;
    }

    .top-bar-infos, .top-bar-infos a {
        text-align: right;
        color:#fff;
        font-family: $fontRegular;
        font-weight:400;
        font-size:14px;
        margin-right: 20px;
        width: 100%;
        text-decoration: none;
    }

    .top-bar-infos a:hover {
        text-decoration: underline;
    }
    
    .nav-bar {
        display:flex;
        height:80px;
        align-items: center;
        justify-content: space-between;
        width: calc(100% - 80px);
        padding:0 40px;
    }

    .menu-container {
        display: flex;
    }

    .menu {
        display: flex;
    }

    .menu a {
        height:40px;
        color:$mainColor;
        display: flex;
        justify-content: center;
        align-items: center;
        margin:0 15px;
        white-space: nowrap;
    }

    .menu a:last-child {
        margin-right: 0;
    }

    .menu-container a {
        text-decoration: none;
        position:relative;
    }

    .menu a:after {
        height:2px;
        width:100%;
        position:absolute;
        content:'';
        background:$mainGradiant;
        border-radius:20px;
        bottom:-5px;
        opacity:0;
        transition:0.2s;
    }

    .menu a:hover:after, .menu a.router-link-exact-active:after {
        opacity: 1;
        bottom:0;
    }

    .menu a:hover, .menu a.router-link-exact-active {
        background: $mainGradiant;
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .menu-demo {
        height:40px;
        padding:0 10px;
        color:#fff;
        border-radius:10px;
        display: flex;
        align-items: center;
        font-size:12px;
        font-weight:700;
        text-transform: uppercase;
        width:160px;
        min-width: 160px;
        justify-content: center;
        background: $pink;
        box-shadow: -180px 0 90px -90px $blue inset; 
        transition: box-shadow 0.5s;
    }

    .menu-demo:hover {
        box-shadow: none;
    }

    .menu-sep {
        height:40px;
        width:1px;
        background:$mainColor;
        margin:0 30px;
    }

    .menu-resp {
        display: none;
    }


    @media screen and (max-width: 1380px) {
        .menu a {
            font-size:14px;
            margin:0 7px;
        }

        .menu-sep {
            margin:0 15px;
        }
    }

    @media screen and (max-width: 1180px) {
        .menu-container{
            display: none;
        }

        .menu-resp {
            display: flex;
            margin-right:10px;
        }

        .burger-nav {
            width: 40px;
            height: 40px;
            position: relative;
            margin: 50px auto;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.5s ease-in-out;
            -moz-transition: 0.5s ease-in-out;
            -o-transition: 0.5s ease-in-out;
            transition: 0.5s ease-in-out;
            cursor: pointer;
        }

        .burger-nav span {
            display: block;
            position: absolute;
            height: 7px;
            width: 100%;
            background: $pink;
            border-radius: 7px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;
        }

        .burger-nav span:nth-child(1) {
            top: 0px;
        }

        .burger-nav span:nth-child(2),
        .burger-nav span:nth-child(3) {
            top: 14px;
        }

        .burger-nav span:nth-child(4) {
            top: 28px;
        }

        .burger-nav.open span:nth-child(1) {
            top: 18px;
            width: 0%;
            left: 50%;
        }

        .burger-nav.open span:nth-child(2) {
            -webkit-transform: rotate(45deg);
            -moz-transform: rotate(45deg);
            -o-transform: rotate(45deg);
            transform: rotate(45deg);
        }

        .burger-nav.open span:nth-child(3) {
            -webkit-transform: rotate(-45deg);
            -moz-transform: rotate(-45deg);
            -o-transform: rotate(-45deg);
            transform: rotate(-45deg);
        }

        .burger-nav.open span:nth-child(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }

        .menu-header-resp-content {
            position: fixed;
            top: 110px;
            width: 300px;
            right: 0;
            background: #fff;
            display: flex;
            flex-direction: column;
        }

        .menu-header-resp-content a {
            width: calc(100% - 40px);
            border: none;
            height: 40px;
            display: flex;
            justify-content: flex-start;
            padding: 0 20px;
            align-items: center;
            text-decoration: none;
            margin:0;
            color:$mainColor;
        }

        .menu-header-resp-content a:not(.menu-demo-resp):hover, .menu-header-resp-content a:not(.menu-demo-resp).router-link-exact-active {
            // background: $mainGradiant;
            // background-clip: text;
            // -webkit-text-fill-color: transparent;
            // -webkit-mask-image: $mainGradiant;
            color:$pink;
        }

        .menu-demo-resp {
            background: $blue;
            //box-shadow: -300px 0 150px -150px $blue inset; 
            //transition: box-shadow 0.5s;
            text-transform: uppercase;
        }

        .menu-header-resp-content a.menu-demo-resp {
            color:#fff;
        }

        .menu-demo-resp:hover, .menu-demo-resp.router-link-exact-active {
            background: $pink;
        }


    }
</style>>

