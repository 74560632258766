<template>
  <div id="app">
      <HeaderApp></HeaderApp>
      <router-view />
      <FooterApp></FooterApp>

      <!-- Google Tag Manager -->
<!-- <script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-KL24LCN');</script> -->
<!-- End Google Tag Manager -->

  </div>
</template>

<script>

import HeaderApp from './components/HeaderApp'
import FooterApp from './components/FooterApp'

export default {
  name: 'App',
  components : {
    FooterApp, HeaderApp
  }
}
</script>

<style lang="scss">

  /* FONT */  

 @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,700&display=swap');


  
body {
    margin:0;
    padding:0;
  }

  #app {
    font-family: $fontRegular;
  }

  .main {
    margin-top:100px;
  }

  h1 {
    color:#fff;
    text-align: center;
    font-size:50px;
    font-weight:700;
    letter-spacing: 2px;
    position: relative;
    line-height: 70px;
  }

  h2 {
    font-size:30px;
    font-weight: 700;
    text-align: center;
  }

  p {
    color:$mainColor;
  }

  .gradiant-text {
    background: $mainGradiant;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .banner {
    min-height: calc(100vh - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .banner-overlay {
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:rgba(0,0,0,0.5);
  }

  .btn, input[type=submit].btn {
    padding:15px 50px;
    font-size:18px;
    font-weight:700;
    text-transform: uppercase;
    border-radius:20px;
    text-decoration: none;
    border:none;
    width: auto;
    cursor: pointer;
  }

  // .btn-gradiant, input[type=submit].btn-gradiant {
  //   background-image: $mainGradiant;
  //   color:#fff;    
  // }

  
  .btn-gradiant, input[type=submit].btn-gradiant {
    background: $pink;
    box-shadow: -190px 0 95px -95px $blue inset; 
    color:#fff;    
    transition: box-shadow 0.5s;
  }

  .btn.btn-gradiant:hover {
     box-shadow:none;
  }

  .sep {
      position: absolute;
      width:100%;
      height:80px;
  }

  .sep-top {
      top:0;
  }

  .sep-bottom {
      bottom:0;
  }

  .loader-container {
    display: flex;
    justify-content: center;
  }

  .notif {
    padding:20px;
    border-radius:10px;
    display: flex;
    justify-content: center;
    align-items:center;
    margin:5px 0;
  }

  .notif p {
    text-align: center;
    color:#fff;
    margin :0;
  }

  .notif.success {
    background:$success;
  }

  .notif.error {
    background: $error;
  }


  /* COLONNES */

  .row {
    width: 100%;
    display: flex;
  }

  .col60 {
    width:58%;
    margin:0 1%;
  }

  .col50 {
    width:48%;
    margin:0 1%;
  }

  .col40 {
    width:38%;
    margin:0 1%;
  }

  .col33 {
    width:31%;
    margin:0 1%;
  }

  .col25 {
    width:23%;
    margin:0 1%;
  }

  .col20 {
    width:18%;
    margin:0 1%;
  }

  .video-vimeo iframe {
    max-width: 98%;
  }

  @media screen and (max-width: 768px) {
    h1 {
      font-size: 30px;
      max-width: 90%;
      margin:inherit 5%;
      line-clamp: 40px;
    }

    h2 {
      font-size: 25px;
      max-width: 90%;
      margin:inherit 5%;
    }

  }
</style>