import { extend } from 'vee-validate';
import { required, max, min, digits, email, mimes, size, length} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Ce champs est requis'
});

extend('mimes', {
  ...mimes,
  message: 'Le type du fichier est invalide'
});

extend('size', {
  ...size,
  message: 'La taille du fichier dépasse 3Mo'
});

extend('max', {
  ...max,
  message: (_, { length }) => `Ce champs ne peut contenir plus de  ${length} caractères`
});

extend('length', {
  ...length,
  message: (_, { length }) => `Ce champs doit comporter  ${length} caractères`
});

extend('min', {
  ...min,
  message: (_, { length }) => `Ce champs doit contenir au moins ${length} caractères`
});

extend('digits', {
  ...digits,
  message: (_, { length }) => `Ce champs doit contenir ${length} chiffres`
});

extend('email', {
  ...email,
  message: 'Le format de l\'email est invalide'
});

extend('date_format', {
  validate: function(value) {
    let parts = value.split('-')
    let month = parseInt(parts[1])
    let day = parseInt(parts[2])
    let year = parseInt(parts[0])
    if(isNaN(month) || isNaN(day) || isNaN(year)){
      return false
    } else if(month < 0 || month > 12) {
      return false
    } else if (day < 0 || day > 31) {
      return false
    } else if( year < 1900 || year > 2100) {
      return false
    } else {
      return true
    }    
  },
  message : 'Le format de la date n\'est pas valide'
});

extend('heure_format', {
  validate(value) {
    let parts = value.split(':')
    let hour = parseInt(parts[0])
    let minutes = parseInt(parts[1])
    if(isNaN(minutes) || isNaN(hour)){
      return false;
    } else if(hour < 0 || hour > 24) {
      return false
    } else if (minutes < 0 || minutes > 60) {
      return false
    } else {
      return true
    }    
  },
  message : 'Le format de l\'heure n\'est pas valide (24h:60min)'
});

extend('min_heure', {
  params: ['target'],
  validate(value, { target }) {
    // heure récupérée
    let parts = value.split(':')
    let hour = parseInt(parts[0])
    let minutes = parseInt(parts[1])
    let timeGet = hour * 60 + minutes
    // comparée à l'heure minimale
    let partsMin = target.split(':')
    let hourMin = parseInt(partsMin[0])
    let minutesMin = parseInt(partsMin[1])
    let timeGetMin = hourMin * 60 + minutesMin

    return timeGet > timeGetMin
    
  },
  message : 'L\'intervalle d\'heure n\'est pas correct'
});

extend('min_today', {
  validate(value) {
    const now = Date.now();
    const dateAccident = new Date(value).getTime()
    return now > dateAccident
  },
  message : 'Vous ne pouvez pas saisir une date future'
});