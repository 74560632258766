import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
        return { selector: to.hash }
    } else if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'Home',
      component: () => import( './views/Home.vue'),
    },
    {
    path: '/a-propos',
        name: 'About',
        component: () => import( './views/About.vue'),
    },
    {
    path: '/contact',
        name: 'Contact',
        component: () => import( './views/Contact.vue'),
    },
    {
    path: '/references',
        name: 'References',
        component: () => import( './views/References.vue'),
    },
    {
    path: '/avantages',
        name: 'Avantages',
        component: () => import( './views/Avantages.vue'),
    },
    {
    path: '/solution',
        name: 'Solutions',
        component: () => import( './views/Solutions.vue'),
    },
    {
    path: '/demonstration',
        name: 'Demo',
        component: () => import( './views/Demo.vue'),
    },
  ]
  
})


export default router;