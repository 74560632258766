<template>
    <footer>
        <div class="footer-main">
            <div class="col40">
                <div class="row">
                    <div class="col50">
                        <div class="footer-links">
                            <router-link :to="{ name : 'Home'}">Accueil</router-link>
                            <router-link :to="{ name : 'Solutions'}">Solutions & Fonctionnalités</router-link>
                            <router-link :to="{ name : 'Avantages'}">Avantages</router-link>
                        </div>
                    </div>
                      <div class="col50">
                        <div class="footer-links">
                            <router-link :to="{ name : 'References'}">Références</router-link>
                            <router-link :to="{ name : 'About'}">A propos de nous</router-link>
                            <router-link :to="{ name : 'Contact'}">Contact</router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col20">
                <img src="./../assets/logo-footer.png" alt="logo footer" class="footer-logo" />
            </div>
            <div class="col40">
                <div class="row">
                    <div class="col50">
                        <div class="footer-infos">
                            <p><a href="tel:0486315384">+33 (0)4 86 31 53 84</a></p>
                            <a href="mailto:contact@synthes3d.com">contact@synthes3d.com</a>
                        </div>
                    </div>
                      <div class="col50">
                        <div class="footer-cities">
                            <div class="footer-item">
                                <img src="./../assets/icon-marker.png" alt="marker" />
                                <div class="city-name">aix-en-provence</div>
                            </div>
                            <div class="footer-item">
                                <img src="./../assets/icon-marker.png" alt="marker" />
                                <div class="city-name">angers</div>
                            </div>      
                            <div class="footer-item">
                                <img src="./../assets/icon-marker.png" alt="marker" />
                                <div class="city-name">usa</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="footer-copyright">
            © Copyright {{ currentYear }} - <a href="https://www.synthes3d.com/" target="_blank">SYNTHES'3D</a> - Tous droits réservés - <a href="https://www.synthes3d.com/fr/mentions-legales/" target="_blank">Mentions légales</a> -  Design & Development by <a href="https://www.synthes3dweb.com/" target="_blank">SYNTHES'3D WEB</a>
        </div>
    </footer>
</template>


<script>
export default {
    name : 'FooterApp',
    data() {
        return {
            currentYear : new Date().getFullYear() 
        }
    }
}
</script>

<style lang="scss" scoped>
    footer {
        background:#fff;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-main {
        display: flex;
        align-items: center;
        margin:20px 0;
        color:$mainColor;
        font-size:15px;
        width: $boxWidth;
        max-width: 96%;
        height:120px;
    }

    .footer-main .col50, .footer-main .col50 {
        display: flex;
        height: 120px;
        align-items: center;
        justify-content: center;
    }

    .footer-copyright {
        padding:10px 0;
        margin:0 20px;
        display: block;
        text-align: center;
        background: $mainGradiant;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size:14px;
        font-weight:700;
        width: $boxWidth;
        max-width: 96%;
    }

    .footer-infos {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer-infos p, .footer-infos a {
        text-align: center;
        text-decoration: none;
        color:$mainColor;
    }

    .footer-main a:hover {
        text-decoration: underline;
    }

    .footer-item {
        display: flex;
        align-items: center;
        margin:5px 0;
    }

    .city-name {
        margin-left: 10px;
        text-transform: uppercase;
    }

    .footer-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .footer-links a {
        margin:5px 0;
        text-decoration: none;
        color:$mainColor;
    }

    .footer-copyright a {
        color:inherit;
        margin:0 3px;
    }

    @media screen and (max-width: 1350px) {
        .footer-main .col40:first-child {
            display: none;
        }

        .col20 {
            width:31%;
            margin: 0 1%;
        }

        .col40 {
            width:64%;
            margin: 0 1%; 
        }
    }

    @media screen and (max-width: 800px) {
        .col20, .col40, .col50 {
            width:98%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .footer-main {
            flex-direction: column;
            height:auto;            
        }

        .row {
            flex-direction: column;
        }
    }

</style>